<template>
  <div class="transaction-history">
    <div class="transaction-history__content">
      <div class="transaction-history__table-wrapper">
        <div class="transaction-history__table-header">
          TRANSACTIONS
        </div>
        <table class="transaction-history__table">
          <thead>
            <tr>
              <th>{{ $t('ID') }}</th>
              <th>{{ $t('Date') }}</th>
              <th>{{ $t('Type') }}</th>
              <th>{{ $t('Status') }}</th>
              <th>{{ $t('Total') }}</th>
              <th>{{ $t('Details') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="depositLoading" class="transaction-history__loading-row">
              <td colspan="6">
                <Spinner />
              </td>
            </tr>
            <tr v-else-if="depositHistory.length === 0" class="transaction-history__no-data-row">
              <td colspan="6">{{ $t('No data found') }}</td>
            </tr>
            <tr v-else v-for="order in depositHistory" :key="order.id">
              <td>{{ order.id }}</td>
              <td>{{ formatDate(order.created_at) }}</td>
              <td>{{ $t('Top-up balance') }}</td>
              <td
                :class="{ success: order.status === 'Success', pending: order.status === 'Pending', error: order.status === 'Error' }">
                <span class="transaction-history__status-icon"></span>
                {{ $t(order.status) }}
              </td>
              <td class="transaction-history__total">{{ order.sum }} {{ order.currency?.code }}</td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
// import DatePicker from 'vue2-datepicker'
// import 'vue2-datepicker/index.css'
// import { formatDate } from '@/utils/helpers'
import Spinner from '@/components/shared/Spinner.vue'
export default {
  name: "DepositHistory",
  props: {},
  components: {
    // DatePicker,
    Spinner
  },
  data() {
    return {
      depositBarMinValue: 0,
      depositBarMaxValue: 0,
      depositDateRange: '',
      depositStatus: '',
      isDropdownOpen: false
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('user/setDepositHistory', [])
    next()
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN
    this.fetchDepositHistory()
    this.clearDepositFilterFields()
  },
  computed: {
    ...mapState('user', ['depositHistory', 'depositLoading']),
    ...mapGetters('app', ['currencySymbol'])
  },
  methods: {
    formatDate(num) {
      let objectDate = new Date(num * 1000);
      let day = objectDate.getDate();
      if (day < 10) {
          day = '0' + day;
      }
      let month = objectDate.getMonth() + 1;
      if (month < 10) {
          month = `0${month}`;
      }
      let year = objectDate.getFullYear();

      function padTo2Digits(x) {
        return String(x).padStart(2, '0');
      }
      let hoursAndMinutes = padTo2Digits(objectDate.getHours()) + ':' + padTo2Digits(objectDate.getMinutes());
      let format = day + "." + month + "." + year + ' ' + hoursAndMinutes;

      return format
    },
    ...mapActions('user', ['fetchDepositHistory']),
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen
    },
    clearDepositFilterFields() {
      this.depositBarMinValue = 0
      this.depositBarMaxValue = 0
      this.depositDateRange = ''
      this.depositStatus = ''
    },
    handleDepositClearButton() {
      this.clearDepositFilterFields()
      this.applyDepositFilters()
    },
    getDepositFilters() {
      return {
        amount_min: this.depositBarMinValue,
        amount_max: this.depositBarMaxValue,
        status: this.depositStatus,
        date_from: this.depositDateRange[0],
        date_to: this.depositDateRange[1]
      }
    },
    async applyDepositFilters() {
      const filters = this.getDepositFilters()
      const result = await this.fetchDepositHistory(filters)
      if (result === 'FETCH_DEPOSIT_HISTORY_ERROR') {
        this.$store.commit('user/setDepositHistory', [])
        this.$toast.error('Failed to fetch deposit history')
      }
    }
  }
}
</script>


<style lang="scss">
.transaction-history {
  &__table-wrapper {
    overflow-x: auto;
  }

  &__table-header {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 26px;
  }


  &__table {
    width: 100%;
    border-collapse: collapse;

    thead {
      th {
        font-size: 14px;
        color: #88868D;
        padding: 16px 12px 16px 12px;
        text-transform: uppercase;
        border-top: 1px solid #626165;
        border-bottom: 1px solid #626165;

        &:first-child {
          padding-left: 0;
        }
      }
    }

    tbody {
      tr {
        height: 42px;
        padding: 12px 0;
        border-bottom: 1px dashed #626165;

        &:hover {
          background-color: rgba(255, 255, 255, 0.831);
          color: #000;

          td.success,
          td.pending,
          td.error {
            color: #000;
          }
        }

        &:last-child {
          border-bottom: none;
        }

        td {
          font-size: 14px;
          padding: 12px;
          vertical-align: middle;
          text-wrap: nowrap;


          &:first-child {
            padding-left: 0;
            width: 8.9576%;
          }

          &.transaction-history__total {
            font-weight: 700;
          }

          &.success,
          &.pending,
          &.error {
            color: #fff;
            display: flex;
            height: 42px;

            .transaction-history__status-icon {
              width: 16px;
              height: 16px;
              border-radius: 50%;
              margin-right: 7px;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 12px;
              font-weight: bold;
              background-color: #39383B;

              &::before {
                font-size: 10px;
              }
            }

            &.success {

              .transaction-history__status-icon {
                color: #7AF73F;

                &::before {
                  content: '✓';
                }
              }
            }

            &.pending {

              .transaction-history__status-icon {
                color: #ffa800;

                &::before {
                  content: '!';
                }
              }
            }

            &.error {

              .transaction-history__status-icon {
                color: #E74040;

                &::before {
                  content: '✕';
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>