<template>
  <main class="main profile-page page-inside" v-if="$parent.isAuth">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <div class="profile-info-container">
            <div class="profile-info-left">
              <div class="profile-image">
                <img :src="user.avatarMedium || require('@/assets/icons/default-profile-icon.svg')" alt="User Avatar" />
              </div>
              <div class="profile-info-row">
                <div class="profile-info-title">Personal information:</div>
                <button class="profile-info-edit button" @click="openAddPersonalInformationModal">{{ $t('Edit')
                  }}</button>
              </div>
              <div class="wallet-info">
                <div class="balance-info">
                  <div class="balance-text">{{ $t('Balance') }}</div>
                  <div class="balance-amount">{{ currencySymbol }} {{ user.balance.toFixed(2) }}</div>
                </div>
                <button class="button" @click="openDepositModal">top up</button>
              </div>
            </div>
            <div class="profile-info-divider"></div>
            <div class="profile-info-right">
              <div class="currency-language-select">
                <CurrencyDropdownMenu />
                <LanguageDropdownMenu />
              </div>

              <div class="profile-info-fields">
                <div class="profile-info-field">
                  <FloatingLabelInput type="email" :placeholder="$t('Email')" :label="$t('Email')" v-model="email" readonly />
                  <button class="change-button button" @click="openChangeEmailModal">{{ $t('Change') }}</button>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="wrapper bottom" ref="wrapperBottom">
          <div class="profile-bottom">
            <div class="profile-tabs">
              <div class="tab-item button"
                :class="{ active: activeTab === 'transactions' || activeTab !== 'order-history' }"
                @click="updateActiveTab('transactions')">
                {{ $t('Transactions') }}
              </div>
              <div class="tab-item button" :class="{ active: activeTab === 'order-history' }"
                @click="updateActiveTab('order-history')">
                {{ $t('Order History') }}
              </div>
            </div>
            <div class="profile-tab-content">
              <!-- <transition name="fade"> -->
              <DepositHistory v-if="activeTab === 'transactions' || activeTab !== 'order-history'" />
              <!-- </transition> -->

              <!-- <transition name="fade"> -->
              <PurchaseHistory v-if="activeTab === 'order-history'" />
              <!-- </transition> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <ChangeEmailModal v-if='changeEmailModalVisible' />
  </main>
</template>
<script>
// import ProfileSettings from '../components/tabs/ProfileSettings.vue'
import DepositHistory from '../components/tabs/DepositHistory.vue'
import PurchaseHistory from '../components/tabs/PurchaseHistory.vue'
import { copyToClipboard } from '@/utils/helpers'
import { mapState, mapGetters, mapActions } from 'vuex'
import CurrencyDropdownMenu from '../components/shared/CurrencyDropdownMenu.vue'
import LanguageDropdownMenu from '../components/shared/LanguageDropdownMenu.vue'
import ChangeEmailModal from '../components/modals/ChangeEmailModal.vue'
export default {
  name: 'Profile',
  props: {
    activeTab: {
      type: String,
      required: true
    }
  },
  components: {
    CurrencyDropdownMenu,
    LanguageDropdownMenu,
    // ProfileSettings,
    DepositHistory,
    PurchaseHistory,
    ChangeEmailModal,
  },
  data: function () {
    return {
      email: '',
      name: '',
      surname: '',
      phone: '',
      profileUpdateSuccessMessaage: '',
      profileUpdateErrorMessaage: '',
      passUpdateSuccessMessaage: '',
      passUpdateErrorMessaage: '',
      phoneFull: '',
      convertedShortName: '',
      countryCode: '',
      countryCodeList: [],
      profilePhone: '',

      countryOptions: [],
      orderHistory: [],
    }
  },
  mounted() {
    this.email = this.user.email
    if (this.$parent.isAuth) {
      this.orderHistory = this.$parent.orderHistory
    } else {
      this.$router.push({ name: 'Home' })
      this.$parent.openLoginModal()
    }
  },
  watch: {
    // $route: 'scrollToBottom'
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapGetters('user', ['fullName']),

    ...mapState('modals', ['depositModalVisible', 'changeEmailModalVisible']),

    ...mapGetters('app', ['currencySymbol']),


  },
  methods: {
    ...mapActions('user', ['fetchAuthStatus']),

    ...mapActions('modals', ['openDepositModal', 'openAddPersonalInformationModal', 'openChangeEmailModal']),
    // scrollToBottom() {
    //   this.$refs.wrapperBottom.scrollIntoView({
    //     behavior: 'smooth',
    //     block: 'start',
    //   });
    // },
    copyToClipboard,
    updateActiveTab(tab) {
      this.$router.push({ query: { tab } })
    },
    openOfferDetailsModal() {
      this.$emit('openOfferDetailsModal')
    },
    // getAllCountryCodes() {
    //   this.$http
    //     .get(process.env.VUE_APP_API + 'phone-codes')
    //     .then((res) => {
    //       if (res.data.status == 'OK') {
    //         this.countryCodeList = res.data.payload
    //         this.countryCode = this.getSymbolsBeforeSpace(this.profilePhone).replace('+', '')
    //         this.getShortNameByPhoneCode(this.countryCode, this.countryCodeList)
    //       }
    //     })
    //     .catch((res) => {
    //       if (res.response.status === 401) {
    //         this.$parent.openLoginModal()
    //       }
    //     })
    // },
    getShortNameByPhoneCode(phoneCode, codeList) {
      for (let i = 0; i < codeList.length; i++) {
        const countryCode = Object.keys(codeList[i])[0]
        const codeValue = codeList[i][countryCode].code
        if (codeValue == phoneCode) {
          this.convertedShortName = countryCode
        }
      }
    },
    getSymbolsBeforeSpace(inputString) {
      const regex = /^[^\s]+/
      const match = inputString.match(regex)

      if (match) {
        return match[0]
      } else {
        return '' // Return an empty string if no symbols are found
      }
    },
    phoneDataUpdate(e) {
      this.phoneFull = e.formatInternational
    },
    // updateProfileData() {
    //   let profileData = {
    //     email: this.email,
    //     name: this.name,
    //     phone: this.phoneFull,
    //     surname: this.surname
    //   }
    //   this.$http
    //     .put(process.env.VUE_APP_API + 'user/profile', profileData)
    //     .then((res) => {
    //       if (res.data.status == 'OK') {
    //         this.profileUpdateSuccessMessaage = 'Success'
    //         setTimeout(() => {
    //           this.profileUpdateSuccessMessaage = ''
    //         }, 3000)
    //       }
    //     })
    //     .catch((res) => {
    //       if (res.response.status === 401) {
    //         this.$parent.openLoginModal()
    //       }
    //       this.profileUpdateErrorMessaage = res.response.data.message
    //       setTimeout(() => {
    //         this.profileUpdateErrorMessaage = ''
    //       }, 3000)
    //     })
    // },
  }
}
</script>
