<template>
  <div class="menu">
    <ul class="nav">
      <li v-for="(item, i) in $parent.nav" class="nav__item" :key="i">
        <router-link :to="item.link">{{ $t(item.title) }}</router-link>
      </li>
      <!-- <div class="nav__item" v-if="$route.name == 'Profile'  || $route.name == 'SellOffers'">
			<a @click="$emit('openSaleSkinModal')">Sale your skin</a>
			</div> -->

      <!-- Cart icon -->
      <button v-if="$parent.isAuth" class="icon" @click.stop="handleOpenCart">
        <img src="@/assets/icons/cart.svg" class="img" />
        <div class="indicator">
          {{ $parent.cartContents.length || 0 }}
        </div>
      </button>

      <!-- User balance -->
      <router-link :to="{ name: 'Profile' }" v-if="$parent.isAuth" class="user-balance">
        <span>{{ currencySymbol }} {{ user.balance }}</span>
        <img src="@/assets/icons/arrow.svg" class="img arrow" />
      </router-link>

    </ul>
  </div>
</template>

<script>
/*import Vue from 'vue';
var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo)*/
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'

export default {
  name: 'Menu',
  props: [],
  data: function () {
    return {}
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapGetters('app', ['currencies', 'currencySymbol'])
  },
  methods: {
    ...mapActions('modals', ['openCartModal', 'closeMobileMenu']),
    ...mapMutations('app', ['setCurrency']),

    handleOpenCart() {
      this.closeMobileMenu();
      this.openCartModal();
    },
    handleCurrencyChange(currencyCode) {
      this.setCurrency(currencyCode)
      this.showDropdown = false
    }
  }
}
</script>


<style lang="scss" scoped>
.icon {
  .indicator {
    background-color: transparent;
  }
}

.arrow {
  filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(26deg) brightness(105%) contrast(101%);
}
</style>
