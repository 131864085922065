<template>
  <div class="product-card">
    <div class="product-card__wrapper">
      <div class="product-card__image-container">
        <img @click="goToSliderItem(item)" :src="imgDomain + item.img_url" class="product-card__image" />
        <transition name="fade">
          <div class="product-card__added-container" v-if="showAddedText">
            <span class="product-card__added-text">{{ $t(showAlreadyInCartText ? 'Item already in cart' : 'Added')
              }}</span>
          </div>
        </transition>
      </div>
      <a @click="goToSliderItem(item)" class="product-card__title">{{ item.title }}</a>
      <div class="product-card__price-container">
        <div class="product-card__price">
          <span class="product-card__price-discount" v-if="item.old_price != '0.00'">{{ currencySymbol }} {{
          item.old_price }}</span>
          <span>{{ currencySymbol }} {{ item.price.toFixed(2) }}</span>
        </div>
      </div>
    </div>
    <div class="product-card__add-to-cart-container">
      <a @click="addToCartHandler(item.id)" class="product-card__add-to-cart"
        :class="{ 'product-card__add-to-cart--show': showAddToCart, 'product-card__add-to-cart--hover': showAddToCart }">
        <img src="@/assets/icons/cart.svg" class="product-card__add-to-cart-icon" />
        <span class="product-card__add-to-cart-text">{{ $t("Add to cart") }}</span>
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mapActions } from "vuex/dist/vuex.common.js";

export default {
  name: "ProductCard",
  props: ["item", "addToCartChosenItem", "sliderIsClickble", "tag"],
  data: function () {
    return {
      imgDomain: "",
      showAddToCart: false,
      showAddedText: false,
      showAlreadyInCartText: false,

    };
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
  },
  methods: {
    ...mapActions('productDetail', ['gotoItem']),
    ...mapActions('user', ['addToCart']),

    async addToCartHandler(id) {
      try {
        await this.addToCart({ id, count: 1 });
        this.showAddedText = true;
        this.showAlreadyInCartText = false;
        setTimeout(() => {
          this.showAddedText = false;
        }, 500);
      } catch (error) {
        if (error.message === 'Item already in cart') {
          this.showAddedText = true;
          this.showAlreadyInCartText = true;
          setTimeout(() => {
            this.showAddedText = false;
            this.showAlreadyInCartText = false;
          }, 500);
        } else {
          console.error('Error adding to cart:', error);
        }
      }
    },

    goToSliderItem(item) {
      this.gotoItem(item.id);
    }
  },
  computed: {
    ...mapGetters("app", ["currencySymbol"]),
  },
};
</script>

<style lang="scss" scoped>
.product-card {
  position: relative;
  flex: 0 1 205px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #2b2a2c;

  &:hover {
    background-color: #47454A;
  }

  &__wrapper {
    padding: 8px 16px 16px 16px;

    .product-card__image-container {
      width: 173px;
      height: 130px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-self: center;

      .product-card__image {
        max-width: 100%;
        max-height: 100%;
        cursor: pointer;
      }

      .product-card__added-text {
        position: absolute;
      }
    }

    .product-card__title {
      line-height: 18px;
      margin-top: 4px;
      max-width: 160px;
      font-size: 14px;
      font-weight: 400;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
      display: inline-block;
    }

    .product-card__price-container {
      margin-top: 8px;

      .product-card__price {
        text-align: left;
        font-size: 16px;
        font-weight: 700;
        line-height: 21px;
        color: $text-color-secondary;
        display: inline-block;

        .product-card__price-discount {
          text-decoration: line-through;
          margin-right: 8px;
        }
      }
    }
  }

  .product-card__add-to-cart-container {
    width: 100%;

    .product-card__add-to-cart {
      text-align: center;
      background: transparent;
      font-family: "Noto Sans", sans-serif;
      font-weight: 700;
      color: #fff;
      border: 1px solid #39383B;
      font-size: 16px;
      padding: 14px 16px;
      transition: 0.3s;
      border-radius: 0 0 2px 2px;
      display: flex;
      align-items: center;
      width: 100%;
      height: 46px;

      &:hover {
        background-color: #fff;
        color: #2B2A2C;

        .product-card__add-to-cart-icon {
          filter: brightness(0) saturate(100%);
        }
      }

      .product-card__add-to-cart-icon {
        width: 14px;
        height: 14px;
        margin-right: 9px;
        filter: none;
      }

      .product-card__add-to-cart-text {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
      }
    }
  }

  a:hover {
    opacity: 1;
  }
}


.product-card__added-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;

  .product-card__added-text {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: #fff;
  }

}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>