<template>
  <div class="slider-container">
    <slick ref="slick" :options="slickOptions" :class="{ 'no-translate': list.length <= slickOptions.slidesToShow }"
      class="slider products">
      <CarouselProductCard v-for="item in list" :key="item.id" :item="item" :tag="tag"
        :addToCartChosenItem="addToCartChosenItem" :sliderIsClickble="sliderIsClickble" />
    </slick>
  </div>
</template>

<script>
import CarouselProductCard from './CarouselProductCard.vue'
import Slick from 'vue-slick'
import { mapGetters } from 'vuex'
export default {
  name: 'Carousel',
  props: ['list', 'tag', 'addToCartChosenItem'],
  components: {
    Slick,
    CarouselProductCard
  },
  data: function () {
    return {
      imgDomain: '',
      sliderIsClickble: true,
      slickOptions: {
        // "dots": true,
        centerMode: true,
        centerPadding: '60px',
        slidesToShow: 3,
        slidesToScroll: 1,
        draggable: false,
        variableWidth: true,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: true,
        responsive: [
          {
            breakpoint: 768, // Adjust breakpoint as needed
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: true // Ensure arrows are enabled on small screens
            }
          }
        ]
        // responsive: [
        //   {
        //     breakpoint: 1601,
        //     settings: {
        //       slidesToShow: 3,
        //       slidesToScroll: 1
        //     }
        //   },
        //   {
        //     breakpoint: 1201,
        //     settings: {
        //       slidesToShow: 2,
        //       slidesToScroll: 1
        //     }
        //   },
        //   {
        //     breakpoint: 901,
        //     settings: {
        //       slidesToShow: 1,
        //       slidesToScroll: 1
        //     }
        //   }
        // ]
      }
    }
  },
  computed: {
    ...mapGetters('app', ['currencyCode']),
  },
  mounted() {
    this.reInit()
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
  },
  watch: {
    list() {
      this.reInit()
    },
    currencyCode() {
      this.reInit()
    },
    deep: true
  },
  methods: {
    noTranslate() {
      return this.list.length <= this.slickOptions.slidesToShow
    },
    reInit() {
      // Helpful if you have to deal with v-for to update dynamic lists
      this.$nextTick(() => {
        this.$refs.slick.reSlick()
      })
    },
    // handleSwipe() {
    //   let self = this
    //   self.sliderIsClickble = false
    //   setTimeout(() => {
    //     self.sliderIsClickble = true
    //   }, 300)
    // },
  }
}
</script>

<style lang="scss">
.slider-container {
  // width: 100vw;

  .slider {
    //   margin-top: 0;
    //   font-size: 0;

    &.slick-slider {
      margin: 20px auto 0;
      overflow: visible;
      overflow-x: clip;
      position: relative;

      &.no-translate {
        @media (min-width: 768px) {

          //left-align if list.length < slidesToShow
          .slick-track {
            transform: none !important;
          }
        }
      }

      .slick-track {
        // transform: translate3d(80px, 0px, 0px);
        display: flex;
        gap: 20px;

        .slick-slide {
          // >div {
          //   display: flex;

          // }
        }
      }
    }

    //   .slider-small {
    //     margin: 0 auto;
    //     display: none;

    //     .slick-list {
    //       width: 100%;
    //       left: 0;
    //       position: relative;
    //       overflow: visible;
    //       z-index: 2;
    //     }
    //   }

    .slick-arrow {
      height: 50px;
      background: transparent;
      position: absolute;
      right: 0px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 0;
      transition: 0.3s;
      background-image: url('~@/assets/icons/next.svg');
      background-size: 16px;
      background-position: center;
      background-repeat: no-repeat;
      z-index: 3;
      filter: brightness(0) saturate(100%) invert(99%) sepia(93%) saturate(2%) hue-rotate(254deg) brightness(108%) contrast(100%);
      padding: 0 30px;

      &:hover {
        opacity: 0.7;
      }

      &.slick-prev {
        left: 0;
        right: auto;
        transform: scaleX(-1) translateY(-50%);
      }

      &.slick-disabled {
        opacity: 0.2 !important;
        pointer-events: none;
      }
    }

  }
}
</style>