<template>
  <div class="transaction-history">
    <div class="transaction-history__content">
      <div class="transaction-history__table-wrapper">
        <div class="transaction-history__table-header">ORDER HISTORY</div>
        <table class="transaction-history__table">
          <thead>
            <tr>
              <th>{{ $t('ID') }}</th>
              <th>{{ $t('Date') }}</th>
              <th>{{ $t('Item') }}</th>
              <th>{{ $t('Item Name') }}</th>
              <th>{{ $t('Game') }}</th>
              <th>{{ $t('Status') }}</th>
              <th>{{ $t('Total') }}</th>
              <th>{{ $t('Details') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="purchaseLoading" class="transaction-history__loading-row">
              <td colspan="8">
                <Spinner />
              </td>
            </tr>
            <tr v-else-if="purchaseHistory.length === 0" class="transaction-history__no-data-row">
              <td colspan="8">{{ $t('No data found') }}</td>
            </tr>
            <tr v-else v-for="purchase in purchaseHistory" :key="purchase.id + '' + purchase.item_id">
              <td>{{ purchase.id }}</td>
              <td>{{ formatDate(purchase.created_at) }}</td>
              <td>
                <img :src="imgDomain + purchase.img_url" alt="Item Image" class="transaction-history__item-image" />
              </td>
              <td>{{ purchase.title }}</td>
              <td class="transaction-history__game">{{ purchase.category == 'dota2' ? 'Dota 2' : 'CS2' }}</td>
              <td
                :class="{ success: purchase.status === 'Success', pending: purchase.status === 'Processing', error: purchase.status === 'Error' }">
                <span class="transaction-history__status-icon"></span>
                {{ $t(purchase.status) }}
              </td>
              <td class="transaction-history__total">{{ purchase.sum.toFixed(2) }} {{ purchase.currency }}</td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
// import DatePicker from 'vue2-datepicker'
// import 'vue2-datepicker/index.css'
// import { formatDate } from '@/utils/helpers'
import Spinner from '@/components/shared/Spinner.vue'
export default {
  props: {},
  components: {
    // DatePicker,
    Spinner
  },
  data() {
    return {
      barMinValue: 0,
      barMaxValue: 0,
      dateRange: '',
      status: '',
      isDropdownOpen: false,
      isLoading: false,
      imgDomain: ''
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('user/setPurchaseHistory', [])
    next()
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN
    this.fetchPurchaseHistory()
    this.clearPurchaseFilterFields()
  },
  computed: {
    ...mapState('user', ['purchaseHistory', 'purchaseLoading']),
    ...mapGetters('app', ['currencySymbol'])
  },
  methods: {
    ...mapActions('user', ['fetchPurchaseHistory']),
    formatDate(num) {
      let objectDate = new Date(num * 1000);
      let day = objectDate.getDate();
      if (day < 10) {
          day = '0' + day;
      }
      let month = objectDate.getMonth() + 1;
      if (month < 10) {
          month = `0${month}`;
      }
      let year = objectDate.getFullYear();

      function padTo2Digits(x) {
        return String(x).padStart(2, '0');
      }
      let hoursAndMinutes = padTo2Digits(objectDate.getHours()) + ':' + padTo2Digits(objectDate.getMinutes());
      let format = day + "." + month + "." + year + ' ' + hoursAndMinutes;

      return format
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen
    },
    clearPurchaseFilterFields() {
      this.barMinValue = 0
      this.barMaxValue = 0
      this.dateRange = ''
    },
    handlePurchaseClearButton() {
      this.clearPurchaseFilterFields()
      this.applyPurchaseFilters()
    },
    getPurchaseFilters() {
      return {
        amount_min: this.barMinValue,
        amount_max: this.barMaxValue,
        date_from: this.dateRange[0],
        date_to: this.dateRange[1]
      }
    },
    async applyPurchaseFilters() {
      const filters = this.getPurchaseFilters()
      const result = await this.fetchPurchaseHistory(filters)
      if (result === 'FETCH_PURCHASE_HISTORY_ERROR') {
        this.$store.commit('user/setPurchaseHistory', [])
        this.$toast.error('Failed to fetch purchase history')
      }
    }
  }
}
</script>


<style lang="scss" scoped>
.transaction-history {
  &__table-wrapper {
    overflow-x: auto;
  }

  &__table-header {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 26px;
  }

  &__table {
    width: 100%;
    border-collapse: collapse;

    thead {
      th {
        font-size: 14px;
        color: #88868d;
        padding: 16px 8px 16px 8px;
        text-transform: uppercase;
        border-top: 1px solid #626165;
        border-bottom: 1px solid #626165;

        &:first-child {
          padding-left: 0;
        }
      }
    }

    tbody {
      tr {
        padding: 8px 0;
        border-bottom: 1px dashed #626165;

        &:hover {
          background-color: rgba(255, 255, 255, 0.831);
          color: #000;

          td.success,
          td.pending,
          td.error {
            color: #000;
          }
        }

        &:last-child {
          border-bottom: none;
        }

        td {
          height: 62px;
          font-size: 14px;
          padding: 8px;

          vertical-align: middle;


          td:not(:first-child) {
            padding-left: 0px;
          }

          &:first-child {
            padding-left: 0;
            width: 8.9576%;
          }

          &.transaction-history__game {
            width: 11.7263%;
          }

          &.transaction-history__total {
            font-weight: 700;
          }

          &.success,
          &.pending,
          &.error {
            color: #fff;
            display: flex;
            align-content: center;
            justify-content: flex-start;
            align-items: center;

            .transaction-history__status-icon {
              width: 16px;
              height: 16px;
              border-radius: 50%;
              margin-right: 7px;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 12px;
              font-weight: bold;
              background-color: #39383B;

              &::before {
                font-size: 10px;
              }
            }

            &.success {

              .transaction-history__status-icon {
                color: #7AF73F;

                &::before {
                  content: '✓';
                }
              }
            }

            &.pending {
              .transaction-history__status-icon {
                color: #ffa800;

                &::before {
                  content: '!';
                }
              }
            }

            &.error {

              .transaction-history__status-icon {
                color: #E74040;

                &::before {
                  content: '✕';
                }
              }
            }
          }

          .transaction-history__item-image {
            width: 62px;
            height: 46px;
            display: block;
          }
        }
      }
    }
  }
}
</style>