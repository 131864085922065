<template>
    <div class="preloader" v-if="!pageLoaded">
        <div class="preloader-content">
            <div class="spinner"></div>
            <p>Loading...</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Preloader',
    props: {
        pageLoaded: {
            type: Boolean,
            required: true,
        },
    },
};
</script>

<style scoped>
.preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #39383B;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.preloader-content {
    text-align: center;
}

.spinner {
    border: 10px solid #f3f3f3;
    border-top: 10px solid #90fc3c;
    border-radius: 50%;
    width: 150px;
    height: 150px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>