<template>
  <footer class="footer">
    <div class="wrapper">
      <div class="footer-top">
        <div class="footer-links">
          <router-link to="/">{{ $t('Home') }}</router-link>
          <router-link to="/products/all">{{ $t('Buy Skins') }}</router-link>
          <router-link v-for="page in pages" :key="page.id" :to="{ name: 'TextPage', params: { slug: page.slug } }">
            {{ $t(page.title) }}
          </router-link>
        </div>
      </div>
    </div>
    <div class="footer-divider"></div>
    <div class="wrapper">
      <div class="footer-bottom">
        <div class="footer-left">
          <img src="@/assets/icons/footer-logo.svg" alt="Footer logo" class="footer-logo" />
          <div class="footer-info">
            <!-- <div class="footer-info-item">
              <span class="footer-info-label">{{ $t('Registration number:') }}</span>
              <span class="footer-info-value">{{ companyNumber }}</span>
            </div>
            <div class="footer-info-item">
              <span class="footer-info-label">{{ $t('Registration address:') }}</span>
              <span class="footer-info-value" v-html="regaddress"></span>
            </div> -->
            <div class="footer-copyright">
              {{ $parent.footerRequisites }}
            </div>
            <div class="footer-copyright">
              {{ $parent.footerCopyright }}
            </div>
          </div>
        </div>
        <div class="footer-right">
          <div class="footer-social">
            <a target="_blank" :href="link.link" v-for="link in $parent.socialLinks" :key="link.img" class="footer-social-link">
              <img :src="link.img" :alt="link.link" />
            </a>
          </div>
          <CurrencyDropdownMenu />
          <div class="footer-payment">
            <img src="@/assets/icons/visa.svg" alt="Visa" class="footer-payment-icon" />
            <img src="@/assets/icons/mastercard.svg" alt="Mastercard" class="footer-payment-icon" />
            <img src="@/assets/icons/3ds.svg" alt="3DS" class="footer-payment-icon" />
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import CurrencyDropdownMenu from './shared/CurrencyDropdownMenu.vue';
export default {
  name: "Footer",
  props: [],
  components: {
    CurrencyDropdownMenu
  },
  data: function () {
    return {
      footerRequisites: '',
      companyAddress: 'Registration address\nNew York, NY 10004',
      currencyCode: 'EUR',
    };
  },
  mounted() {
  },
  computed: {
    ...mapGetters('app', ['currencySymbol']),
    ...mapState('staticPages', ['pages']),
    companyName() {
      if (this.$parent.footerRequisites && this.$parent.footerRequisites.includes(',')) {
        return this.$parent.footerRequisites.split(',')[0].trim();
      }
      return '';
    },
    companyNumber() {
      if (this.$parent.footerRequisites && this.$parent.footerRequisites.includes(',')) {
        return this.$parent.footerRequisites.split(',')[1].replace('Company Number: ', '').trim();
      }
      return '';
    },
    regaddress() { return 'SKUNLOCK LTD,<br>Company Number: 15407967' },
  },
  methods: {
  }
};
</script>


<style lang="scss" scoped>
.footer {
  background-color: $bg-light-gray;
  color: #fff;
  padding: 22px 0 15px 0;
  margin: 0 auto;

  .footer-divider {
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.15);
    margin: 22px 0 32px 0;
  }

  .wrapper {
    .footer-top {
      .footer-links {
        display: flex;
        justify-content: flex-start;
        gap: 32px;

        a {
          color: #fff;
          text-decoration: none;
          text-transform: uppercase;
          font-size: 14px;
          font-weight: 700;
          line-height: 2;
        }
      }
    }

    .footer-bottom {
      display: flex;
      justify-content: space-between;
      gap: 15px;

      .footer-left {
        display: flex;
        flex-direction: column;

        .footer-logo {
          width: 195.5px;
          height: 44px;
          margin-bottom: 28px;
        }

        .footer-info {
          &-item {
            display: flex;
            align-items: flex-start;
            gap: 8px;
            margin-bottom: 20px;

            .footer-info-label {
              color: $text-color-description;
              font-size: 14px;
              line-height: 1.5;
            }

            .footer-info-value {
              color: #fff;
              font-size: 14px;
              line-height: 1.5;
            }
          }

          .footer-copyright {
            color: $text-color-description;
            font-size: 14px;
            max-width: 576px;

            &:not(:last-child) {
              margin-bottom: 12px;
            }
          }
        }
      }

      .footer-right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between; // Add this line to vertically center the content
        flex-grow: 1; // Add this line to make the footer-right take up the full height

        .footer-social {
          display: flex;
          gap: 22px;
          margin-bottom: 34px;

          &-link {
            img {
              width: 32px;
              height: 32px;
              display: block;
            }
          }
        }
        
        .footer-payment {
          display: flex;
          gap: 45px;
          margin-top: auto; // This will move the payment method to the bottom

          img {
            max-block-size: none;
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .footer {
    .wrapper {
      .footer-top {
        .footer-links {
          flex-wrap: wrap;
          justify-content: center;
        }
      }

      .footer-bottom {
        flex-direction: column;
        align-items: flex-start;

        .footer-right {
          align-items: flex-start;
          gap: 10px;
        }
      }
    }
  }
}
</style>