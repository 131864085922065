<template>
  <main class="main page-inside payment-result-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <div class="title big">Unfortunately, we were unable to process your payment. Please try again later</div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
  export default {
    name: 'PaymentFailPage',
    props: [],
    components: {},
    data: function () {
      return {}
    },
    methods: {}
  }
</script>
