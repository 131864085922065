<template>
  <div class="checkout">
    <div class="checkout__content">
      <a class="checkout__back-button" @click="$router.back()">
        <img src="../../src/assets/icons/arrow.svg" alt="Back" class="checkout__back-icon" />
        <span>{{ $t('Back') }}</span>
      </a>

      <div class="checkout__container">
        <div class="checkout__left">
          <h2 class="checkout__heading">{{ $t('Checkout') }}</h2>

          <div class="checkout__alert-block" v-if="$parent.totalSum > user.balance">
            <div class="checkout__alert-left">
              <div class="checkout__alert-content">
                <img src="../../src/assets/icons/alert.svg" alt="Alert" class="checkout__alert-icon" />
                <div class="checkout__alert-text">
                  <h3 class="checkout__alert-title">{{ $t('Not enough funds') }}</h3>
                  <p>You cannot complete your order because you don't have enough funds in your account.</p>
                </div>
              </div>
              <router-link :to="{ name: 'Profile', query: { tab: 'account-balance' } }"
                class="checkout__button checkout__button--primary">{{ $t('Top up balance') }}</router-link>
            </div>
          </div>

          <div class="checkout__cart-block">
            <div class="checkout__cart-header">
              <div class="checkout__cart-line">
                <h2 class="checkout__heading">{{ $t('Shopping cart') }}</h2>
                <div class="checkout__cart-count">{{ cartContents.length || 0 }} {{ $t('items') }}</div>
              </div>
              <div class="checkout__cart-actions" @click="handleClearCartButton">
                <img src="../../src/assets/icons/trash-bin.svg" alt="Clear cart" class="checkout__cart-icon" />
                <span>{{ $t('Clear cart') }}</span>
              </div>
            </div>

            <div class="checkout__order-separator"></div>

            <template v-for="(item, index) in cartContents">
              <div class="checkout__cart-item" :key="item.item.item_id">
                <div class="checkout__cart-item-info">
                  <img :src="imgDomain + item.item.img_url" alt="Item" class="checkout__cart-item-image" />
                  <div>
                    <p class="checkout__cart-item-weapon">{{ item.item.type }}</p>
                    <p class="checkout__cart-item-name">{{ item.item.title }}</p>
                    <p class="checkout__cart-item-exterior">{{ item.item.type }}</p>
                  </div>
                </div>
                <div class="checkout__cart-item-price">
                  <div class="checkout__cart-item-price-text">
                    {{ currencySymbol }} {{ item.item.price.toFixed(2) }}
                  </div>
                  <img src="../../src/assets/icons/trash-bin.svg" alt="Remove" class="checkout__cart-item-icon"
                    @click="$parent.removeFromCart({ item_id: item.item_id })" />
                </div>
              </div>
              <div class="checkout__order-separator" :key="item.item.item_id" v-if="index !== cartContents.length - 1">
              </div>
            </template>

            <div v-if="cartContents.length === 0" class="checkout__empty-cart">
              <!-- <img src="../../src/assets/empty-cart.svg" alt="Empty cart" class="checkout__empty-cart-icon" /> -->
              <p>{{ $t('Your cart is empty') }}</p>
            </div>
          </div>
        </div>

        <div class="checkout__right">
          <div class="checkout__order-summary">
            <h2 class="checkout__heading">{{ $t('Order Summary') }}</h2>
            <p class="checkout__order-info">
              <span class="bold">{{ cartContents.length }} {{ $t('items') }}</span> {{ $t('in the cart') }}
            </p>
            <div class="checkout__order-row">
              <span class="checkout__order-label">Steam {{ $t('price') }}:</span>
              <span>{{ currencySymbol }} {{ $parent.totalSum }}</span>
            </div>
            <div class="checkout__order-separator"></div>
            <!-- <div class="checkout__order-row">
              <span class="checkout__order-label">You will save:</span>
              <span>$ 12345.67</span>
            </div> -->
            <div class="checkout__order-total">
              <span>{{ $t('Total') }}:</span>
              <span>{{ currencySymbol }} {{ $parent.totalSum }}</span>
            </div>
            <div v-if="$parent.totalSum > user.balance" class="checkout__deposit-info">
              <img src="../../src/assets/icons/alert.svg" alt="Alert" class="checkout__deposit-icon" />
              <span>{{ $t('You need to deposit at least') }} {{ currencySymbol
                }}{{ ($parent.totalSum - user.balance).toFixed(2) }}
                {{ $t('to purchase the items in your cart') }}.</span>
            </div>
            <div class="checkout__deposit_topup">
              <router-link v-if="$parent.totalSum > user.balance"
                :to="{ name: 'Profile', query: { tab: 'account-balance' } }"
                class="checkout__button checkout__button--primary">{{ $t('Top up balance') }}</router-link>
              <button v-else :disabled="$parent.totalSum <= 0 || (!user.tradeLink && !tradeUrlUpdated)" class="checkout__button checkout__button--primary"
                @click="submit">
                {{ $t('Checkout') }}
              </button>
            </div>
          </div>
          <div class="profile-info-field">
            <FloatingLabelInput type="text" :placeholder="$t('Trade URL')" :label="$t('Trade URL')"
              v-model="tradeUrl" />
            <!-- <button class="save-button button" @click="saveTradeLink">{{ $t('Save') }}</button> -->
            <button v-if="tradeUrlChanged" class="save-button button" @click="saveTradeLink">
              {{ $t('Save') }}
            </button>
            <a v-else href="https://steamcommunity.com/id/me/tradeoffers/privacy#trade_offer_access_url" target="_blank"
              class="save-button button">{{ $t('View Trade Link')
              }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import Vue from 'vue'
export default {
  name: 'Checkout',
  props: [],
  data: function () {
    return {
      imgDomain: '',
      countryOptions: [],
      countryOptionsId: [],
      name: '',
      surname: '',
      phone: '',
      steamId: '',
      country: '',
      countryId: '',
      city: '',
      address: '',
      postCode: '',
      terms: false,
      phoneFull: '',
      convertedShortName: '',
      countryCode: '',
      countryCodeList: [],
      profilePhone: '',
      countryOptionsIso: [],
      tradeUrl: '',
      tradeUrlUpdated: false
    }
  },
  mounted() {
    this.tradeUrl = this.user.tradeLink
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN
    this.fetchProfile()
    this.fetchCartContents()
  },
  computed: {
    tradeUrlChanged() {
      return this.tradeUrl !== this.user.tradeLink;
    },
    ...mapState('user', ['user']),
    ...mapGetters('user', ['cartContents']),
    ...mapGetters('app', ['currencySymbol', 'currencyCode'])
  },
  methods: {
    ...mapActions('user', ['handlePurchase', 'fetchProfile', 'fetchCartContents', 'clearCart', 'setSteamTradeLink']),
    ...mapActions('modals', ['openAddPersonalInformationModal']),

    saveTradeLink() {
      this.$http
        .put(process.env.VUE_APP_API + 'user/update-trade-link', { url: this.tradeUrl })
        .then(() => {
          this.setSteamTradeLink(this.tradeUrl);
          this.tradeUrlUpdated = true;
          this.$toast.success('Trade link updated successfully');
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message);
        });
    },

    async submit() {

      if (!this.user.steamId) {
        this.openAddPersonalInformationModal();
        this.$toast.warning('Please add your steamId')
        return;
      }

      try {
        const payload = {
          currency: this.currencyCode
        }
        await this.handlePurchase(payload)
        this.fetchProfile()
        this.fetchCartContents()
        this.$router.push({ name: 'Profile', query: { tab: 'order-history' } })
        Vue.$toast.success('Purchased successfully')
      } catch (error) {
        // Handle purchase error
        Vue.$toast.error(error.response.data.message)
      }
    },
    getAllCountryCodes() {
      this.$http
        .get(process.env.VUE_APP_API + 'phone-codes')
        .then((res) => {
          if (res.data.status == 'OK') {
            this.countryCodeList = res.data.payload
            this.countryCode = this.getSymbolsBeforeSpace(this.profilePhone).replace('+', '')
            this.getShortNameByPhoneCode(this.countryCode, this.countryCodeList)
          }
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openLoginModal()
          }
        })
    },
    getShortNameByPhoneCode(phoneCode, codeList) {
      for (let i = 0; i < codeList.length; i++) {
        const countryCode = Object.keys(codeList[i])[0]
        const codeValue = codeList[i][countryCode].code
        if (codeValue == phoneCode) {
          this.convertedShortName = countryCode
        }
      }
    },
    getSymbolsBeforeSpace(inputString) {
      const regex = /^[^\s]+/
      const match = inputString.match(regex)

      if (match) {
        return match[0]
      } else {
        return '' // Return an empty string if no symbols are found
      }
    },
    phoneDataUpdate(e) {
      this.phoneFull = e.formatInternational
    },
    async handleClearCartButton() {
      const result = await this.clearCart()
      if (result === 'CLEAR_CART_ERROR') {
        this.$toast.error('Failed to clear cart. Please try again later.')
      } else {
        this.$toast.success('Cart cleared successfully')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.checkout {
  &__content {
    display: flex;
    align-items: flex-start;
    gap: 68px;
    max-width: 1218px;
    margin: 0 auto;
    margin-top: 42px;
    padding: 0 10px;

    @media (max-width: 1200px) {
      flex-direction: column;
      gap: 30px;
      flex-wrap: wrap;
      align-content: center;
    }
  }

  &__back-button {
    color: #fff;
    display: flex;
    align-items: center;
    opacity: 0.5;
    font-size: 12px;
    cursor: pointer;
    height: 32px;
  }

  &__back-icon {
    margin-right: 7px;
    width: 12px;
    height: 12px;
    transform: scaleX(-1);
  }

  &__container {
    display: flex;
    gap: 38px;
    width: 100%;

    @media (max-width: 900px) {
      flex-direction: column;

      .checkout__left {
        order: 2;
        margin-bottom: 40px;
      }

      .checkout__right {
        order: 1;
        min-width: 100%;
      }
    }
  }

  &__left {
    flex: 1 1 656px;
  }

  &__right {
    flex: 1 1 410px;
  }

  &__heading {
    font-size: 24px;
    line-height: 1.3;
    font-weight: 700;

    @media (max-width: 460px) {
      font-size: 18px;
    }
  }

  &__alert-block {
    background-color: rgba(208, 4, 4, 0.1);
    border-radius: 4px;
    background-image: repeating-linear-gradient(-28deg,
        #ff0000,
        #ff0000 6px,
        transparent 6px,
        transparent 9px,
        #ff0000 9px),
      repeating-linear-gradient(62deg, #ff0000, #ff0000 6px, transparent 6px, transparent 9px, #ff0000 9px),
      repeating-linear-gradient(152deg, #ff0000, #ff0000 6px, transparent 6px, transparent 9px, #ff0000 9px),
      repeating-linear-gradient(242deg, #ff0000, #ff0000 6px, transparent 6px, transparent 9px, #ff0000 9px);
    background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
    background-position: 0 0, 0 0, 100% 0, 0 100%;
    background-repeat: no-repeat;
    padding: 20px 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 18px 0;
  }

  &__alert-left {
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 460px) {
      flex-direction: column;
    }
  }

  &__alert-content {
    display: flex;
  }

  &__alert-icon {
    margin-right: 28px;
  }

  &__alert-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    margin-right: 12px;
    max-width: 375px;

    h3 {
      font-weight: 700;
      font-size: 14px;
      line-height: 1.3;
    }

    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 1.3;
    }
  }

  &__cart-block {
    border: 1px solid #fff;
    padding: 26px 16px 10px 16px;
    margin-top: 18px;

    @media (max-width: 460px) {
      padding: 26px 10px 10px 10px;
    }
  }

  &__cart-line {
    display: flex;
    align-items: baseline;
    gap: 16px;

    .checkout__cart-count {
      font-size: 14px;
      font-weight: 700;
      line-height: 1.3;
      color: $text-color-primary;
    }
  }

  &__cart-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px 16px 16px;
  }

  &__cart-actions {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__cart-icon {
    margin-right: 8px;
  }

  &__cart-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 16px 11px 16px;
  }

  &__cart-item-info {
    display: flex;
    align-items: center;
    font-size: 12px;

    .checkout__cart-item-weapon {
      line-height: 1.3;
      opacity: 0.5;
    }

    .checkout__cart-item-name {
      line-height: 1.3;
      font-size: 14px;
      font-weight: 700;
    }

    .checkout__cart-item-exterior {
      line-height: 1.3;
      font-size: 14px;
    }
  }

  &__cart-item-image {
    margin-right: 32px;
    position: relative;
    width: 71px;
    height: 53px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      z-index: 0;
    }
  }

  &__cart-item-price {
    display: flex;
    align-items: center;
    margin-left: 10px;

    .checkout__cart-item-price-text {
      font-size: 14px;
      font-weight: 700;
      line-height: 1.3;
      text-wrap: nowrap;
    }
  }

  &__cart-item-icon {
    margin-left: 94px;
    cursor: pointer;

    @media (max-width: 460px) {
      margin-left: 35px;
    }
  }

  &__order-summary {
    border: 1px solid #fff;
    padding: 32px 16px;
    border-radius: 4px;

    .checkout__heading {
      margin-bottom: 14px;
      padding-left: 16px;
      padding-right: 16px;
    }

    .checkout__order-info {
      margin-bottom: 36px;
      font-size: 14px;
      line-height: 1.3;
      padding-left: 16px;
      padding-right: 16px;
    }

    &+.profile-info-field {
      margin-top: 12px;
      display: flex;
      gap: 8px;
      align-items: center;

      .button {
        padding: 15px;
      }
    }
  }

  &__order-separator {
    width: 100%;
    margin: 10px auto;
    padding: 0 16px;
    height: 2px;
    background-color: $bg-dark-gray;
  }

  &__empty-cart {
    text-align: center;
  }

  &__order-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    line-height: 1.3;
    font-size: 14px;
    font-weight: 700;
    padding-left: 16px;
    padding-right: 16px;

    .checkout__order-label {
      color: #969595;
    }
  }

  &__order-total {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    margin-top: 18px;
    margin-bottom: 24px;
    padding: 14px 16px;
    line-height: 1.3;
    font-size: 18px;
    font-weight: 700;
    background-color: $bg-dark-gray;
    border-radius: 4px;
  }

  &__deposit-info {
    display: flex;
    align-items: flex-start;
    margin-bottom: 48px;
    padding-left: 16px;
    padding-right: 16px;

    span {
      max-width: 318px;
      font-size: 14px;
      line-height: 1.3;
      color: $text-color-primary;
    }
  }

  &__deposit-icon {
    margin-right: 20px;
    width: 16px;
    height: 16px;
  }

  &__deposit_topup {
    display: flex;
    justify-content: flex-end;
  }

  &__button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  &__button--primary {
    background-color: $bg-dark-gray;
    color: $text-color-primary;
    font-size: 16px;
    line-height: 1.5;
    padding: 11px 16px;
    border: 1px solid $bg-light-gray;
    height: 46px;
    text-wrap: nowrap;

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    @media (max-width: 460px) {
      margin-top: 12px;
    }
  }
}
</style>
