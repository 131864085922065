<template>
  <main class="main home-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="home">
          <div class="left">
            <div class="title big">
              {{ $t('BUY SKINS') }}
              <vue-typed-js :typeSpeed="150" :backSpeed="30" :loop="true" :strings="['CS2', 'Dota 2']">
                <span class="green typing"></span>
              </vue-typed-js>
            </div>
            <div class="desc">{{ $t('Forge your identity.') }} {{ $t('Find unique Dota 2 & CS 2 skins.') }}</div>
            <button v-if="!$parent.isAuth" class="button" @click="$parent.openLoginModal">START NOW</button>
            <router-link v-else to="/products/all" class="button">START NOW</router-link>
          </div>
        </div>
      </div>
      <div class="section product-listing-page">
        <PreviewProductList />
        <LoginOverlay :isAuth="$parent.isAuth" :openLoginModal="$parent.openLoginModal">
          <h2 class="title">Please login</h2>
          <button v-if="!$parent.isAuth" class="button" @click="$parent.openLoginModal">Login</button>
        </LoginOverlay>
      </div>

      <div class="parallax-section" v-parallax="0.3">
        <div class="strike-a-style-section-top">
          <div class="strike-a-style-container">
            <div class="strike-text">Strike</div>
            <div class="a-style-text">a Style</div>
            <div class="image-wrapper">
              <img src="../assets/strike-a-style_top.png" alt="Strike a Style" />
            </div>
            <div class="cs2-skins-text">CS2 Skins</div>
            <div class="await-text">Await!</div>
          </div>
          <router-link to="/products/cs2/all" class="button">search now</router-link>
        </div>

        <div class="strike-a-style-section-bottom">
          <div class="strike-a-style-container">
            <div class="dota2-skins-text">Dota 2 Skins</div>
            <div class="image-wrapper">
              <img src="../assets/strike-a-style_bottom.png" alt="" />
            </div>
            <div class="for-every-hero-text">
              <div class="text">for Every Hero!</div>
              <router-link to="/products/dota2/all" class="button">search now</router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="best-sellers-section cs2">
        <div class="wrapper">
          <div class="left-side">
            <img src="../assets/icons/cs-logo.svg" alt="CSGO Logo" class="logo" />
          </div>
          <div class="text">BEST SELLERS</div>
        </div>

        <Carousel v-if="$parent.newArrivalsCs2.length" :list="$parent.newArrivalsCs2" :tag="''" />
      </div>

      <div class="best-sellers-section dota2">
        <div class="wrapper">
          <div class="left-side">
            <img src="../assets/icons/dota-logo.svg" alt="Dota 2 Logo" class="logo" />
          </div>
          <div class="text">BEST SELLERS</div>
        </div>
        <Carousel v-if="$parent.newArrivalsDota2.length" :list="$parent.newArrivalsDota2" :tag="''" />
      </div>
    </div>
  </main>
</template>
<script>
import Carousel from '@/components/Carousel.vue'
import PreviewProductList from '@/components/PreviewProductList.vue'
import LoginOverlay from '@/components/shared/LoginOverlay.vue'
export default {
  name: 'Home',
  props: ['addToCartChosenItem'],
  data: function () {
    return {
      imgDomain: ''
    }
  },
  components: {
    Carousel,
    PreviewProductList,
    LoginOverlay,
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN
  },
  methods: {
  }
}
</script>


<style scoped lang="scss">
.typed-element {
  display: inline;
}

.home-page {
  .hero-section {
    background-image: url('~@/assets/hero.png');
    background-size: cover;
    aspect-ratio: 1440 / 590;
    background-position: center;
    background-repeat: no-repeat;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 auto;

    .home {
      margin: 20px 20px 20px 8.19444vw;
    }
  }
}

.section.product-listing-page {
  position: relative;
  z-index: 1;

  .title {
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 32px;
  }
}


.parallax-section {
  position: relative;
  z-index: 0;
  padding-top: 115px;
  padding-bottom: 152px;
  background-image: url('~@/assets/strike-a-style_bg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 150px;

  .strike-a-style-section-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 34px;

    position: relative;
    height: 486px;

    font-weight: 700;

    .button {
      margin: 0 auto;
    }

    .strike-a-style-container {
      width: 985px;
      height: 486px;
      position: relative;

      text-transform: uppercase;


      .strike-text,
      .a-style-text {
        position: absolute;
        top: 20px;
        left: 0;
        font-size: 86px;
        color: #fff;
      }

      .strike-text {
        left: 90px;
      }

      .a-style-text {
        top: 125px;
      }

      .center-content {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 100%;
        display: flex;
        align-items: center;
      }

      .image-wrapper {
        position: absolute;
        top: 0;
        left: 202px;
        width: 512px;
        height: 384px;
        clip-path: polygon(7% 80%, 38% 4%, 57% 1%, 86% 68%, 75% 78%, 65% 85%, 54% 99%);
        overflow: hidden;
      }

      .image-wrapper img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .cs2-skins-text,
      .await-text {
        position: absolute;
        right: 0;
        top: 125px;
        font-size: 86px;
        color: #fff;
      }

      .cs2-skins-text {
        color: #90fc3c;
      }

      .await-text {
        right: 90px;
        top: 225px;
      }
    }

  }

  .strike-a-style-section-bottom {
    font-weight: 700;
    text-transform: uppercase;
    position: relative;
    height: 360px;
    overflow: hidden;
    max-width: 1212px;
    width: 100%;

    .strike-a-style-container {
      height: 100%;
      position: relative;
      margin: 0 auto;
      display: flex;
    }

    .dota2-skins-text {
      position: absolute;
      left: 0;
      top: 0;
      text-wrap: nowrap;
      font-size: 64px;
      color: #90FC3C;
    }

    .image-wrapper {
      width: 858px;
      height: 273.17px;
      position: absolute;
      left: 275px;
      // clip-path: polygon(0 0, 100% 0, 50% 100%);
      // overflow: hidden;
    }

    .image-wrapper img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .for-every-hero-text {
      position: absolute;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      gap: 52px;
    }

    .for-every-hero-text .text {
      font-size: 64px;
      font-weight: 700;
      color: #fff;
    }

    .for-every-hero-text .button {}

  }
}


.best-sellers-section {
  position: relative;
  z-index: 1;
  padding-bottom: 112px;
  background: linear-gradient(to bottom, #2B2A2C, #131214);


  .wrapper {
    display: flex;
    gap: 32px;
    align-items: center;


    .left-side {
      background-color: $button-bg-color;
      width: 100px;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0px 0px 8px 8px;
      padding: 28px 12px 25px 12px;

      .logo {
        width: 100%;
        height: 100%;
      }
    }

    .text {
      font-weight: 700;
      text-transform: uppercase;
      font-size: 32px;
      color: #fff;
    }
  }
}

@media (max-width: 1024px) { 
  .parallax-section { 
    gap: 40px; 
 
    .strike-a-style-section-top, 
    .strike-a-style-section-bottom { 
      gap: 20px; 
 
      .strike-a-style-container { 
        width: 100%; 
        height: auto; 
        display: flex; 
        flex-direction: column; 
        align-items: center; 
        text-align: center; 
 
        .strike-text, 
        .a-style-text, 
        .cs2-skins-text, 
        .await-text, 
        .dota2-skins-text, 
        .for-every-hero-text .text { 
          position: static; 
          font-size: 48px; 
        } 
 
        .image-wrapper { 
          position: static; 
          width: 100%; 
          height: auto; 
          clip-path: none; 
        } 
 
        .for-every-hero-text { 
          position: static; 
          flex-direction: column; 
          gap: 20px; 
        } 
      } 
    } 
 
    .strike-a-style-section-top { 
      height: auto; 
 
    } 
 
    .strike-a-style-section-bottom { 
      height: auto; 
    } 
  } 
}



@media (max-width: 600px) {
  .home-page {
    .hero-section {
      background: none;
    }
  }
}
</style>