<template>
  <div id="app" :class="['app ' + $route.name]">
    <Preloader :pageLoaded="pageLoaded" />

    <transition name="fade">
      <div id="page" class="page" v-if="pageLoaded">
        <transition name="fade">
          <Menu v-if="mobileMenuVisible" />
        </transition>
        <div class="mob menu-icon" @click="toggleMobileMenu" :class="{ active: mobileMenuVisible }">
          <span></span>
          <span></span>
          <span></span>
        </div>
        <Header :cartContents="cartContents" @logout="logout" />
        <router-view :addToCartChosenItem="addToCartChosenItem" @addToCart="addToCartHandler" @login="handleLogin" />
        <div class="footer-container">
          <Footer v-if="!['Profile', 'Checkout'].includes($route.name)" />
        </div>
        <transition name="fade">
          <Login v-if="LoginModalVisible" @closeLoginModal="closeLoginModal"
            @openRegistrationModal="openRegistrationModal" @login="handleLogin" :is-verified="isVerified"
            @resendConfirmation="resendConfirmationLink" />
        </transition>

        <transition name="fade">
          <ForgotPasswordModal v-if="ForgotPasswordModalVisible" @forgotPass="handleForgotPassword" />
        </transition>

        <transition name="fade">
          <Registration v-if="RegistrationModalVisible" @closeRegistrationModal="closeRegistrationModal"
            @openLoginModal="openLoginModal" @registration="handleRegistration" />
        </transition>
        <transition name="fade">
          <CookiesConsentModal v-if="cookiesModalVisible && !isOnCookiePolicyorNoticePage" />
        </transition>

        <transition name="fade">
          <DepositModal v-if="depositModalVisible" />
        </transition>

        <transition name="fade">
          <TradeURLModal v-if="tradeUrlModalVisible" />
        </transition>

        <transition name="fade">
          <AddPersonalInformationModal v-if="addPersonalInformationModalVisible" />
        </transition>

      </div>
    </transition>
  </div>
</template>

<script>
import Preloader from '@/components/shared/Preloader.vue';

import Header from './components/Header.vue'
import Menu from './components/Menu.vue'
import Footer from './components/Footer.vue'
// import OfferDetailsModal from "./components/OfferDetailsModal.vue";
// import SaleSkinModal from './components/SaleSkinModal.vue'
import Login from './components/modals/LoginModal.vue'
import Registration from './components/modals/RegistrationModal.vue'
import { loadScriptsFromString } from '@/utils/load_scripts'
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
import CookiesConsentModal from './components/modals/CookiesConsentModal.vue'
import ForgotPasswordModal from './components/modals/ForgotPasswordModal.vue'
import DepositModal from './components/modals/DepositModal.vue'
import TradeURLModal from './components/modals/TradeURLModal.vue';
import AddPersonalInformationModal from './components/modals/AddPersonalInformationModal.vue';
export default {
  name: 'app',
  components: {
    Header,
    Menu,
    Footer,
    // OfferDetailsModal,
    // SaleSkinModal,
    Registration,
    Login,
    ForgotPasswordModal,
    CookiesConsentModal,
    DepositModal,
    TradeURLModal,
    AddPersonalInformationModal,
    Preloader,
  },
  data: function () {
    return {
      pageLoaded: false,
      // offerDetailsModalVisible: false,
      // saleSkinModalVisible: false,
      nav: [
        {
          id: 'shop',
          title: this.$t('Shop'),
          link: '/products/all'
        },
        {
          id: 'new-skins',
          title: this.$t('New skins'),
          link: '/products/new-arrivals'
        }
      ],
      successMessage: '',
      orderHistory: [],
      orderToView: [],
      addToCartChosenItem: '',
      newArrivalsCs2: [],
      newArrivalsDota2: [],
      textPageTitle: '',
      textPageHeader: '',
      textPageContent: '',
      similarProducts: '',
      imgDomain: '',
      textPageList: '',
      hostname: '',
      footerRequisites: '',
      footerSupportEmail: '',
      footerCopyright: '',
      socialLinks: [],
      isVerified: true,
      // orderModalVisible: false,
      orderData: {},
      minAmount: '',
      paymentMethods: [],
      appDomain: '',
    }
  },
  watch: {
    currencyCode() {
      this.getNewArrivals()
      if (this.$route.name === 'Home') {
        this.getNewArrivals();
      }
      if (this.isAuth) {
        this.fetchCartContents()
        this.fetchProfile()
      }
    },
    $route() {
      // Check if the current route is the profile page and the tab query parameter is set to 'transactions' or 'order-history'
      if (this.$route.name === 'Profile' && ['transactions', 'order-history'].includes(this.$route.query.tab)) {
        // Do not scroll to the top
        return;
      }

      window.scrollTo(0, 0);
      this.closeMobileMenu();
      this.cookiesModal();
    },
  },
  methods: {
    formatDate(num) {
      let objectDate = new Date(num * 1000);
      let day = objectDate.getDate();
      if (day < 10) {
          day = '0' + day;
      }
      let month = objectDate.getMonth() + 1;
      if (month < 10) {
          month = `0${month}`;
      }
      let year = objectDate.getFullYear();

      function padTo2Digits(x) {
        return String(x).padStart(2, '0');
      }
      let hoursAndMinutes = padTo2Digits(objectDate.getHours()) + ':' + padTo2Digits(objectDate.getMinutes());
      let format = day + "." + month + "." + year + ' ' + hoursAndMinutes;

      return format
    },
    getSocialLinks() {
      this.$http.get(process.env.VUE_APP_API + 'social-links')
        .then(({ data }) => {
          this.socialLinks = data.payload;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    ...mapActions('modals', [
      'openRegistrationModal',
      'closeRegistrationModal',
      'openForgotPasswordModal',

      'openLoginModal',
      'closeLoginModal',

      'openCookiesModal',

      'openDepositModal',
      'closeDepositModal',

      'closeMobileMenu',
      'toggleMobileMenu'
    ]),

    ...mapActions('app', ['fetchLanguages', 'fetchCurrencies']),

    ...mapMutations('user', ['setIsAuth', 'setError']),
    ...mapActions('user', [
      'login',
      'register',
      'fetchProfile',
      'fetchPurchaseHistory',
      'fetchDepositHistory',
      'fetchCartContents',
      'addToCart',
      'removeFromCart',
      'clearCart',
      'fetchAuthStatus',
      'logout'
    ]),

    ...mapActions('staticPages', ['fetchStaticPages']),

    async addToCartHandler(id) {
      try {
        await this.addToCart({ id, count: 1 });
        this.$toast.success('Item added to cart', {
          timeout: 1500
        });
        // setTimeout(() => {
        //   this.showAddedText = false;
        // }, 500);
      } catch (error) {
        if (error.message === 'Item already in cart') {
          this.$toast.warning('Item already in cart', {
            timeout: 1500
          });
          // setTimeout(() => {
          //   this.showAddedText = false;
          //   this.showAlreadyInCartText = false;
          // }, 500);
        } else {
          console.error('Error adding to cart:', error);
        }
      }
    },

    // orderSubmit(data, type) {
    //   data.paymentType = type
    //   this.$http
    //     .post(process.env.VUE_APP_API + 'orders/checkout', data)
    //     .then((res) => {
    //       if (res.data.status == 'ERROR') {
    //         this.$router.push({ name: 'PaymentFailPage' })
    //         this.orderModalVisible = false
    //       } else {
    //         window.location = res.data.redirect_url
    //       }
    //     })
    //     .catch((res) => {
    //       this.setError(res.response.data.message)
    //     })
    // },
    // openOrderModal(data) {
    //   if (this.totalSum < 5) {
    //     this.setError('Sorry, but minimum order amount is ' + this.minAmount + ' ' + this.currencyCode)
    //   } else {
    //     this.setError('')
    //     this.orderModalVisible = true
    //     this.orderData = data
    //   }
    // },
    // closeOrderModal() {
    //   this.orderModalVisible = false
    //   this.setError('')
    // },
    getGoogleTagManagerSnippet() {
      this.$http
        .get(process.env.VUE_APP_API + 'settings')
        .then((res) => {
          this.paymentMethods = res.data.payload.payment_methods
          this.minAmount = res.data.payload.min_top_up
          this.footerRequisites = res.data.payload.requisites
          this.footerSupportEmail = res.data.payload.support_email
          this.footerCopyright = res.data.payload.copyright
          const snippet = res.data.payload.google_tag

          if (snippet) {
            loadScriptsFromString(snippet)
          }
        })
        .catch((error) => {
          console.error('Error fetching Google Tag Manager snippet:', error)
        })
    },
    getDomainName(hostName) {
      return hostName.substring(hostName.lastIndexOf('.', hostName.lastIndexOf('.') - 1) + 1)
    },
    menuHeight() {
      const doc = document.documentElement
      doc.style.setProperty('--vh', `${window.innerHeight * 0.01}px`)
    },
    clearError() {
      this.setError('')
    },
    async handleLogin(data) {
      const { success, error } = await this.login(data)
      if (success) {
        this.closeLoginModal()
        this.$router.push({ name: 'Profile' })
      } else {
        this.setError(error)
      }
    },

    resendConfirmationLink(data) {
      this.setError('')
      this.$http
        .post(process.env.VUE_APP_API + 'resendConfirmationLink', { email: data })
        .then((res) => {
          if (res.data.status == 'OK') {
            this.successMessage = this.$t('Confirmation link has been resent.')
          } else {
            this.setError(res.data.message)
          }
        })
        .catch((res) => {
          this.setError(res.response.data.message)
        })
    },
    handleForgotPassword(data) {
      let self = this
      this.$http
        .post(process.env.VUE_APP_API + 'user/reset-password', data)
        .then((res) => {
          if (res.data.status == 'OK') {
            this.successMessage = 'Success'
            this.setError('')
            setTimeout(() => {
              self.successMessage = ''
            }, 2000)
          } else {
            this.setError(res.response.data.message)
          }
        })
        .catch((error) => {
          this.setError(error.response.data.message)
        })
    },
    // registration(data) {
    //   this.clearError();
    //   this.isVerified = true;
    //   let self = this;
    //   this.$http.post(process.env.VUE_APP_API + "register", data)
    //     .then((res) => {
    //       if (res.status === 200 && res.data.status === "OK") {
    //         this.clearError();
    //         self.isAuth = true;
    //         this.closeRegistrationModal();
    //         self.$router.push({ path: "/profile" });
    //       } else if (res.status === 202 && res.data.status === "VERIFY") {
    //         this.error = "Your registration is being processed. Please check your email for a verification link.";
    //       } else {
    //         this.error = res.data.message;
    //       }
    //     })
    //     .catch((error) => {
    //       if (error.response.status >= 400 && error.response.status < 500) {
    //         this.setError(error.response.data.message);
    //       } else {
    //         this.error = "An error occurred. Please try again later.";
    //       }
    //     });
    // },
    async handleRegistration(data) {
      const { success, error } = await this.register(data)
      if (success) {
        this.closeRegistrationModal()
        this.fetchAuthStatus()
        this.$router.push({ name: 'Profile' })
      } else {
        this.setError(error)
      }
    },
    getNewArrivals() {
      this.$http
        .get(process.env.VUE_APP_API + 'items/list?new=true&currency=' + this.currencyCode + '&category=cs2')
        .then((res) => {
          this.newArrivalsCs2 = res.data.payload
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.setIsAuth(false)
          }
        })

      this.$http
        .get(process.env.VUE_APP_API + 'items/list?new=true&currency=' + this.currencyCode + '&category=dota2')
        .then((res) => {
          this.newArrivalsDota2 = res.data.payload
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.setIsAuth(false)
          }
        })
    },
    // getRecommended() {
    //   this.$http
    //     .get(process.env.VUE_APP_API + 'items/recommended')
    //     .then((res) => {
    //       this.recommendedProducts = res.data.payload
    //     })
    //     .catch((res) => {
    //       if (res.response.status === 401) {
    //         this.setIsAuth(false)
    //       }
    //     })
    // },
    cookiesModal() {
      const cookiesAccepted = localStorage.getItem('cookiesAccepted')
      if (!cookiesAccepted) {
        this.openCookiesModal()
      }
    },
  },
  mounted: async function () {
    this.getSocialLinks();
    this.cookiesModal()
    await this.fetchCurrencies()
    await this.fetchLanguages()
    await this.fetchAuthStatus()
    console.log('auth: ' + this.isAuth)
    this.hostname = this.getDomainName(window.location.hostname)
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN
    this.appDomain = process.env.VUE_APP_DOMAIN
    this.pageLoaded = true
    let self = this
    window.addEventListener('resize', self.menuHeight)
    self.menuHeight()
    self.getGoogleTagManagerSnippet()
    // this.getRecommended()
    this.getNewArrivals()
    // this.getTextPages()
    this.fetchStaticPages()
  },
  computed: {
    ...mapState({
      currentLanguage: (state) => state.app.currentLanguage
    }),
    ...mapState('modals', ['RegistrationModalVisible', 'LoginModalVisible', 'cookiesModalVisible', 'ForgotPasswordModalVisible', 'depositModalVisible', 'mobileMenuVisible', 'tradeUrlModalVisible', 'addPersonalInformationModalVisible']),
    ...mapGetters('app', ['currencyCode']),
    ...mapGetters('user', ['totalSum']),
    ...mapState('user', ['cartContents', 'isAuth', 'error']),
    isOnCookiePolicyorNoticePage() {
      return this.$route.path === '/pages/cookie-notice' || this.$route.path === '/pages/cookie-policy'
    }
  }
}
</script>
